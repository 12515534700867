//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
    EventEmitter,
    Output,
} from "@angular/core";
import { ItemActionsMap, ActionListI, S25BulkEditUtil, S25BulkMap, BulkEditTypes } from "./s25.bulk.edit.util";
import { Location } from "@angular/common";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { FlsService } from "../../services/fls.service";
import { ContactService } from "../../services/contact.service";
import { UserprefService } from "../../services/userpref.service";
import { LynxService } from "../../services/lynx.service";
import { TelemetryService } from "../../services/telemetry.service";
import { S25Util } from "../../util/s25-util";
import { Fls } from "../../pojo/Fls";

@TypeManagerDecorator("s25-ng-bulk-edit")
@Component({
    selector: "s25-ng-bulk-edit",
    template: `
        <h1>{{ this.itemTypeName }} Actions</h1>
        <div class="c-margin-left--half">
            <label>
                <span class="ngBold c-margin-bottom--double  c-margin-right--quarter">Select Action: </span>
                <select
                    *ngIf="actionList"
                    class="ngListPageDropdown"
                    name="list"
                    [(ngModel)]="chosenAction"
                    (change)="onChange($event)"
                >
                    <option *ngIf="actionList.length > 1"></option>
                    <option *ngFor="let a of actionList" [value]="a.value">{{ a.name }}</option>
                </select>
            </label>
        </div>

        <div *ngIf="chosenAction" class="c-margin-left--half">
            <div [ngSwitch]="chosenAction">
                <div *ngSwitchCase="'editCat'">
                    <s25-ng-bulk-edit-multiselect
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'category'"
                        [idsOnly]="this.idsOnly"
                    ></s25-ng-bulk-edit-multiselect>
                </div>

                <div *ngSwitchCase="'eventName'">
                    <s25-ng-bulk-edit-event-title-name
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'name'"
                    ></s25-ng-bulk-edit-event-title-name>
                </div>

                <div *ngSwitchCase="'eventTitle'">
                    <s25-ng-bulk-edit-event-title-name
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'title'"
                    ></s25-ng-bulk-edit-event-title-name>
                </div>

                <div *ngSwitchCase="'editPrimaryOrg'">
                    <s25-ng-bulk-edit-dropdown
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'primaryOrg'"
                        [hasContactDropdown]="false"
                    ></s25-ng-bulk-edit-dropdown>
                </div>

                <div *ngSwitchCase="'addContactRole'">
                    <s25-ng-bulk-edit-dropdown
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'contactRoles'"
                        [hasContactDropdown]="true"
                        [excludeIds]="[]"
                    ></s25-ng-bulk-edit-dropdown>
                </div>

                <div *ngSwitchCase="'removeContactRole'">
                    <s25-ng-bulk-edit-dropdown
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'contactRoles'"
                        [hasContactDropdown]="false"
                        [excludeIds]="[-1, -2]"
                    ></s25-ng-bulk-edit-dropdown>
                </div>

                <div *ngSwitchCase="'unassignLocations'">
                    <s25-ng-bulk-edit-unassign [itemIds]="this.itemIds"></s25-ng-bulk-edit-unassign>
                </div>

                <div *ngSwitchCase="'eventState'">
                    <s25-ng-bulk-edit-state
                        [itemIds]="this.itemIds"
                        [itemType]="'eventStates'"
                        [itemTypeId]="this.itemTypeId"
                    ></s25-ng-bulk-edit-state>
                </div>

                <div *ngSwitchCase="'addRequirement'">
                    <s25-ng-bulk-event-requirement
                        [itemIds]="this.itemIds"
                        [remove]="false"
                    ></s25-ng-bulk-event-requirement>
                </div>

                <div *ngSwitchCase="'removeRequirement'">
                    <s25-ng-bulk-event-requirement
                        [itemIds]="this.itemIds"
                        [remove]="true"
                    ></s25-ng-bulk-event-requirement>
                </div>

                <div *ngSwitchCase="'delete'">
                    <s25-ng-bulk-edit-delete
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                    ></s25-ng-bulk-edit-delete>
                </div>

                <div *ngSwitchCase="'eventInherit'">
                    <s25-ng-bulk-edit-inherit
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [chosenModels]="this.chosenModels"
                        [itemType]="'inherit'"
                    ></s25-ng-bulk-edit-inherit>
                </div>

                <div *ngSwitchCase="'editOrg'">
                    <s25-ng-bulk-edit-multiselect
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'organization'"
                        [idsOnly]="this.idsOnly"
                    ></s25-ng-bulk-edit-multiselect>
                </div>

                <div *ngSwitchCase="'editInherit'">
                    <s25-ng-bulk-edit-inherit
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [chosenModels]="this.chosenModels"
                        [itemType]="'inherit'"
                    ></s25-ng-bulk-edit-inherit>
                </div>

                <div *ngSwitchCase="'constraints'">
                    <s25-ng-bulk-edit-constraint
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [chosenModels]="this.chosenModels"
                        [itemType]="'constraint'"
                    ></s25-ng-bulk-edit-constraint>
                </div>

                <div *ngSwitchCase="'editDateRange'">
                    <s25-ng-bulk-edit-date-range
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [chosenModels]="this.chosenModels"
                        [itemType]="'constraint'"
                    ></s25-ng-bulk-edit-date-range>
                </div>

                <div *ngSwitchCase="'editEventTypeReport'">
                    <s25-ng-bulk-edit-event-type-reports
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'eventTypeReports'"
                    ></s25-ng-bulk-edit-event-type-reports>
                </div>

                <div *ngSwitchCase="'editAtrb'">
                    <!-- event type, folder, cabinet -->
                    <s25-ng-bulk-edit-multiselect
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'eventCustomAttributes'"
                        [idsOnly]="this.idsOnly"
                    ></s25-ng-bulk-edit-multiselect>
                </div>

                <div *ngSwitchCase="'addCustAtrb'">
                    <!-- event, org, location and resource -->
                    <s25-ng-bulk-edit-cust-atrb [itemTypeId]="itemTypeId" [itemIds]="itemIds" [itemType]="'custAtrb'">
                    </s25-ng-bulk-edit-cust-atrb>
                </div>

                <div *ngSwitchCase="'removeCustAtrb'">
                    <s25-ng-bulk-edit-cust-atrb
                        [itemTypeId]="itemTypeId"
                        [itemIds]="itemIds"
                        [itemType]="'custAtrb'"
                        [remove]="true"
                    >
                    </s25-ng-bulk-edit-cust-atrb>
                </div>

                <div *ngSwitchCase="'editReqCal'">
                    <s25-ng-bulk-edit-multiselect
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'eventRequirements'"
                        [idsOnly]="this.idsOnly"
                    ></s25-ng-bulk-edit-multiselect>
                </div>

                <div *ngSwitchCase="'editReqOther'">
                    <s25-ng-bulk-edit-multiselect
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'eventOtherRequirements'"
                        [idsOnly]="this.idsOnly"
                    ></s25-ng-bulk-edit-multiselect>
                </div>

                <div *ngSwitchCase="'editActivation'">
                    <s25-ng-bulk-edit-activation
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'activation'"
                    ></s25-ng-bulk-edit-activation>
                </div>

                <div *ngSwitchCase="'editRole'">
                    <s25-ng-bulk-edit-multiselect
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'eventRoles'"
                        [idsOnly]="this.idsOnly"
                    ></s25-ng-bulk-edit-multiselect>
                </div>

                <div *ngSwitchCase="'editNotifyPolicy'">
                    <s25-ng-bulk-edit-notify-policy
                        [itemTypeId]="this.itemTypeId"
                        [itemTypeName]="this.itemTypeName"
                        [itemIds]="this.itemIds"
                        [chosenModels]="this.chosenModels"
                    ></s25-ng-bulk-edit-notify-policy>
                </div>

                <div *ngSwitchCase="'editCopy'">
                    <s25-ng-bulk-edit-copy
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [chosenModels]="this.chosenModels"
                        [itemType]="'copy'"
                    ></s25-ng-bulk-edit-copy>
                </div>

                <div *ngSwitchCase="'eventType'">
                    <s25-ng-bulk-edit-dropdown
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'eventTypes'"
                    ></s25-ng-bulk-edit-dropdown>
                </div>

                <div *ngSwitchCase="'editSpaceBuilding'">
                    <s25-ng-bulk-edit-dropdown
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'buildings'"
                    ></s25-ng-bulk-edit-dropdown>
                </div>

                <div *ngSwitchCase="'editLayout'">
                    <s25-ng-bulk-location-edit-layout-capacity
                        [itemTypeId]="this.itemTypeId"
                        [locationIds]="this.itemIds"
                        [type]="'layout'"
                    ></s25-ng-bulk-location-edit-layout-capacity>
                </div>

                <div *ngSwitchCase="'removeLayout'">
                    <s25-ng-bulk-edit-multiselect
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'layout'"
                    ></s25-ng-bulk-edit-multiselect>
                </div>

                <div *ngSwitchCase="'editSpaceCap'">
                    <s25-ng-bulk-location-edit-layout-capacity
                        [itemTypeId]="this.itemTypeId"
                        [locationIds]="this.itemIds"
                        [type]="'cap'"
                    ></s25-ng-bulk-location-edit-layout-capacity>
                </div>

                <div *ngSwitchCase="'editSpacePartition'">
                    <s25-ng-bulk-location-edit-partition
                        [itemTypeId]="this.itemTypeId"
                        [locationIds]="this.itemIds"
                    ></s25-ng-bulk-location-edit-partition>
                </div>

                <div *ngSwitchCase="'editSpaceFeature'">
                    <s25-ng-bulk-edit-multiselect
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'feature'"
                    ></s25-ng-bulk-edit-multiselect>
                </div>

                <div *ngSwitchCase="'editSpacefillRatio'">
                    <s25-ng-bulk-location-edit-fillratio
                        [itemTypeId]="this.itemTypeId"
                        [locationIds]="this.itemIds"
                    ></s25-ng-bulk-location-edit-fillratio>
                </div>

                <div *ngSwitchCase="'editSpaceDefaultInstr'">
                    <s25-ng-bulk-edit-comment-instr
                        [itemTypeId]="this.itemTypeId"
                        [locationIds]="this.itemIds"
                        [type]="'instruction'"
                    ></s25-ng-bulk-edit-comment-instr>
                </div>

                <div *ngSwitchCase="'editSpaceScheduler'">
                    <s25-ng-bulk-location-edit-scheduler
                        [itemTypeId]="this.itemTypeId"
                        [locationIds]="this.itemIds"
                    ></s25-ng-bulk-location-edit-scheduler>
                </div>

                <div *ngSwitchCase="'editRelate'">
                    <s25-ng-bulk-edit-relationship
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                    ></s25-ng-bulk-edit-relationship>
                </div>

                <div *ngSwitchCase="'editSpaceExpress'">
                    <s25-ng-bulk-location-edit-express
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                    ></s25-ng-bulk-location-edit-express>
                </div>

                <div *ngSwitchCase="'editSpaceOptEffDating'">
                    <s25-ng-bulk-location-edit-optimizer-effective-dating
                        [itemIds]="this.itemIds"
                    ></s25-ng-bulk-location-edit-optimizer-effective-dating>
                </div>

                <div *ngSwitchCase="'editSpaceHours'">
                    <s25-ng-bulk-location-edit-hours
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                    ></s25-ng-bulk-location-edit-hours>
                </div>

                <div *ngSwitchCase="'editComments'">
                    <s25-ng-bulk-edit-comment-instr
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [type]="'comment'"
                    ></s25-ng-bulk-edit-comment-instr>
                </div>

                <div *ngSwitchCase="'editDefaultInstr'">
                    <s25-ng-bulk-edit-comment-instr
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [type]="'instruction'"
                    ></s25-ng-bulk-edit-comment-instr>
                </div>

                <div *ngSwitchCase="'editResStock'">
                    <s25-ng-bulk-resource-edit-stock
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                    ></s25-ng-bulk-resource-edit-stock>
                </div>

                <div *ngSwitchCase="'editOrgTypes'">
                    <s25-ng-bulk-edit-dropdown
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'type'"
                    ></s25-ng-bulk-edit-dropdown>
                </div>

                <div *ngSwitchCase="'editOrgRating'">
                    <s25-ng-bulk-edit-dropdown
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'rating'"
                    ></s25-ng-bulk-edit-dropdown>
                </div>

                <div *ngSwitchCase="'editAcctCode'">
                    <s25-ng-bulk-edit-text
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'accountingCode'"
                    ></s25-ng-bulk-edit-text>
                </div>

                <div *ngSwitchCase="'editOrgContacts'">
                    <s25-ng-bulk-edit-org-contacts
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                    ></s25-ng-bulk-edit-org-contacts>
                </div>

                <div *ngSwitchCase="'editOrgPartitions'">
                    <s25-ng-bulk-edit-org-partition-prefs
                        [itemIds]="this.itemIds"
                    ></s25-ng-bulk-edit-org-partition-prefs>
                </div>

                <div *ngSwitchCase="'editTaskRespond'">
                    <s25-ng-bulk-edit-date
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'respondBy'"
                    ></s25-ng-bulk-edit-date>
                </div>

                <div *ngSwitchCase="'editTaskComments'">
                    <s25-ng-bulk-edit-comment-instr
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [type]="'comment'"
                        [textArea]="true"
                    ></s25-ng-bulk-edit-comment-instr>
                </div>

                <div *ngSwitchCase="'editTaskAssignee'">
                    <s25-ng-bulk-edit-assignee
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'assignee'"
                    ></s25-ng-bulk-edit-assignee>
                </div>

                <div *ngSwitchCase="'editTaskActions'">
                    <s25-ng-bulk-edit-task-action
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'action'"
                    ></s25-ng-bulk-edit-task-action>
                </div>

                <div *ngSwitchCase="'eventFolder'">
                    <s25-ng-bulk-edit-dropdown
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'eventFolder'"
                    ></s25-ng-bulk-edit-dropdown>
                </div>

                <div *ngSwitchCase="'eventOwner'">
                    <s25-ng-bulk-edit-event-owner
                        [itemTypeId]="this.itemTypeId"
                        [itemIds]="this.itemIds"
                        [itemType]="'eventOwner'"
                    ></s25-ng-bulk-edit-event-owner>
                </div>

                <div *ngSwitchDefault>Ooops... no match found</div>
            </div>
        </div>

        <div *ngIf="itemTypeId !== 7 && itemTypeId !== 11 && itemTypeId !== 12">
            <button class="aw-button aw-button--outline" (click)="backToList()">Return to List</button>
            <button
                class="aw-button aw-button--primary c-margin-bottom--single c-margin-top--single c-margin-left--quarter c-margin-right--half"
                (click)="goBack()"
            >
                Return to Search
            </button>
        </div>
    `,
    styles: `
        s25-event-type-list s25-ng-bulk-edit s25-generic-dropdown div,
        s25-ng-event-type-hierarchy s25-ng-bulk-edit s25-generic-dropdown div {
            display: block;
            max-width: 400px;
        }

        .ngDropdownPaginated {
            max-width: 350px;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25BulkEditComponent implements OnInit {
    @Output() modelValueChange = new EventEmitter<boolean>();
    @Input() itemIds?: number[] = [];
    @Input() chosenModels?: any = [];
    @Input() itemTypeId: number;
    @Input() itemTypeName: string;
    @Input() idsOnly: boolean = false; // Event cat pass ids instead of the object, so make this flexible either just ids or the object
    init: boolean = false;
    actionList: ActionListI[] = [];
    chosenAction: string; // = action list value
    MAX_ITEM_COUNT: number = 1000;
    fls: any;
    groupId: number;
    userName: string;
    actionType: string;
    searchCriteriaName: string;
    actionListPromise: Promise<any>;

    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
        private cd: ChangeDetectorRef,
        private location: Location,
    ) {}

    async ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        this.searchCriteriaName = S25BulkMap[this.itemTypeId]?.custAtrb?.searchCriteriaName;

        this.actionListPromise = this.getActionList().then(([actionList, fls]) => {
            this.actionList = actionList;
            this.fls = fls;
        });

        await this.moreData();

        if (this.actionList && this.actionList.length === 1) {
            this.chosenAction = this.actionList[0].value;
        }

        this.init = true;
        this.cd.detectChanges();
    }

    async getActionList() {
        return S25Util.all({
            fls: FlsService.getFls(),
            itemTypeId: this.itemTypeId,
        }).then(function (resp) {
            let fls = resp.fls;
            let itemTypeId = resp.itemTypeId;

            let actionList = ItemActionsMap[itemTypeId].filter(function (option: any) {
                let hasPerms: boolean = !option?.fls_name || fls[option.fls_name] === "F";
                return hasPerms;
            });

            return [actionList, fls];
        });
    }

    async moreData() {
        const [userName, groupId, lynx, _] = await Promise.all([
            ContactService.getCurrentUsername(),
            UserprefService.getGroupId(),
            LynxService.getInstances(),
            this.actionListPromise,
        ]);

        this.groupId = groupId;
        this.userName = userName;

        if (this.itemTypeId === 7) {
            this.idsOnly = true;
        }

        if (this.itemTypeId === 1) {
            this.fls.DIRECT_UNASSIGN === "F" &&
                this.actionList.push({ value: "unassignLocations", name: "Unassign Locations" });

            if (this.groupId === -1 || ["F", "C"].indexOf(this.fls.EVENT_EVS) > -1)
                this.actionList.push(
                    { value: "addRequirement", name: "Add/Edit Event Requirement" },
                    { value: "removeRequirement", name: "Remove Event Requirement" },
                );

            if (this.groupId === -1 || this.userName === "service25" || this.fls.EVENT_DELETE == "F") {
                this.actionList.push({ value: "delete", name: "Delete Events" });
            }

            if ((this.groupId === -1 || this.userName === "service25") && lynx.length === 0) {
                this.actionList.push({ value: "eventInherit", name: "Inherit Events" });
            }

            if (this.groupId === -1 || this.userName === "service25") {
                this.actionList.push({ value: "eventFolder", name: "Change Events Folder" });
            }

            if (
                this.groupId === -1 ||
                (this.fls.EVENT_EVS === "F" &&
                    this.fls.EVENT_PERM === "F" &&
                    ["F", "C", "R"].indexOf(this.fls.CU_CONTACT) > -1)
            )
                this.actionList.push({ value: "eventOwner", name: "Change Event Ownership" });
        }

        if (this.itemTypeId === 4) {
            if (
                this.groupId === -1 ||
                this.userName === "service25" ||
                (this.fls.SPACE_LIST === "F" && this.fls.SPACE_PERM === "F")
            ) {
                this.actionList.push({
                    value: "editSpaceBuilding",
                    name: "Edit Location Building",
                });
            }
        }
    }

    onChange($event: any) {
        if ($event) {
            this.chosenAction = $event.target.value;
            let subCategory = BulkEditTypes[this.itemTypeId];
            if (subCategory) {
                subCategory = S25Util.firstCharToUpper(subCategory);
                TelemetryService.sendWithSub("BulkEdit", subCategory, this.chosenAction);
            }
        } else {
            this.chosenAction = "";
        }

        if (this.itemTypeId === 1 && this.chosenAction === "editCat") this.idsOnly = true;

        setTimeout(() => {
            this.cd.detectChanges();
        }, 60);
    }

    backToList() {
        this.modelValueChange.emit(false);
        this.cd.detectChanges();
    }

    goBack() {
        history.back();
    }
}
