import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { Table } from "../s25-table/Table";
import { Bind } from "../../decorators/bind.decorator";
import { ListService } from "../../services/list.service";
import { OptService } from "../../services/opt.service";
import { OptColumnChooser, ViewButtonValue } from "../s25-opt/s25.opt.component";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Proto } from "../../pojo/Proto";
import { S25TableComponent } from "../s25-table/s25.table.component";
import { PreferenceService } from "../../services/preference.service";
import { S25FavoriteSimpleComponent } from "../s25-favorite/s25.favorite.simple.component";
import { S25ItemGenericComponent } from "../s25-item/item-object/s25.item.generic.component";
import { GenericTableListComponent } from "../s25-table/generics/generic.table.list.component";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { UserprefService } from "../../services/userpref.service";
import { Item } from "../../pojo/Item";
import { S25Const } from "../../util/s25-const";
import { TaskService } from "../../services/task/task.service";
import { S25TaskEditComponent } from "../s25-task/s25.task.edit.component";
import { S25TaskActionComponent } from "../s25-task/s25.task.action.component";
import { TableEditableDateComponent } from "../s25-table/generics/table-editable-date.component";
import { ContactService } from "../../services/contact.service";
import { Task } from "../../pojo/Task";
import { S25TaskStatusComponent } from "../s25-task/s25.task.status.component";
import ISODateString = Proto.ISODateString;
import { TaskTiersService } from "../../services/task/task.tiers.service";
import { S25TableUtil } from "../s25-table/s25.table.util";
import { S25ItemI } from "../../pojo/S25ItemI";
import { SearchService } from "../../services/search/search.service";

const stickyColumnPreference = {
    [Item.Ids.Event]: "25L_event_search_columns",
    [Item.Ids.Location]: "25L_space_search_columns",
    [Item.Ids.Resource]: "25L_resource_search_columns",
    [Item.Ids.Organization]: "25L_org_search_columns",
    [Item.Ids.Task]: "25L_task_overview_columns",
    [Item.Ids.Contact]: "25L_cont_search_columns",
} as any;

@TypeManagerDecorator("s25-ng-bulk-edit-data-list")
@Component({
    selector: "s25-ng-bulk-edit-data-list",
    template: `
        <div *ngIf="!action">
            <h3>{{ header }}</h3>
            <a class="aw-button aw-button--outline" href="#!/home/search">Cancel</a>
            <button
                class="aw-button aw-button--primary c-margin-bottom--single c-margin-top--single c-margin-left--quarter c-margin-right--half"
                [disabled]="selectedItems?.length < 1"
                (click)="goToAction()"
            >
                Go to Actions
            </button>
        </div>

        <s25-ng-table
            *ngIf="isInit && !action"
            [columnSortable]="true"
            [dataSource]="tableData"
            [stickyColumnSortPreference]="S25Const.itemId2Name[type]"
            [unlimitedWidth]="true"
            [pivotThreshold]="0"
            [hasSelect]="true"
            [hasSelectAll]="true"
            (selectedChange)="selectedChange($event)"
        ></s25-ng-table>

        <div *ngIf="!action">
            <a class="aw-button aw-button--outline" href="#!/home/search">Cancel</a>
            <button
                class="aw-button aw-button--primary c-margin-bottom--single c-margin-top--single c-margin-left--quarter c-margin-right--half"
                [disabled]="selectedItems?.length < 1"
                (click)="goToAction()"
            >
                Go to Actions
            </button>
        </div>

        <s25-ng-bulk-edit
            *ngIf="isInit && action"
            [itemTypeId]="type"
            [chosenModels]="chosenModels"
            [itemIds]="selectedItems"
            (modelValueChange)="actionChange($event)"
        ></s25-ng-bulk-edit>
    `,
    styles: `
        ::ng-deep s25-ng-bulk-edit-data-list tbody tr td input[type="checkbox"] {
            height: 16px !important;
        }

        ::ng-deep s25-ng-bulk-edit-data-list s25-item-generic {
            color: rgb(37, 115, 167);
        }

        ::ng-deep s25-ng-bulk-edit-data-list s25-item-generic s25-popover > .ngInlineBlock {
            display: block !important;
        }

        ::ng-deep s25-ng-bulk-edit-data-list .s25-ng-table--cell.stateTentative {
            font-style: italic;
        }

        ::ng-deep s25-ng-bulk-edit-data-list .s25-ng-table--cell.stateCancelled {
            color: #d62000;
            font-weight: 400;
        }

        ::ng-deep .nm-party--on s25-ng-bulk-edit-data-list .s25-ng-table--cell.stateCancelled {
            color: #ff837a;
        }
        ::ng-deep s25-ng-bulk-edit-data-list s25-ng-table .italic {
            font-style: italic;
        }

        ::ng-deep s25-ng-bulk-edit-data-list s25-ng-task-edit > span {
            display: block !important;
        }

        ::ng-deep s25-ng-bulk-edit-data-list s25-ng-table s25-ng-editable-date .editable-input {
            width: 100% !important;
        }

        ::ng-deep s25-ng-bulk-edit-data-list s25-ng-table thead th.s25TableColumn {
            color: #000000cc !important;
            font-size: 14px;
            padding: 1em 0.5em !important;
        }

        ::ng-deep .nm-party--on s25-ng-bulk-edit-data-list s25-ng-table thead th.s25TableColumn {
            color: #fff !important;
        }

        ::ng-deep s25-ng-bulk-edit-data-list s25-ng-table .s25-ng-table--table {
            border: 1px solid #e5e5e5;
        }

        ::ng-deep s25-ng-bulk-edit-data-list s25-ng-table tbody tr:not(:last-of-type) {
            border-bottom: 1px solid #e5e5e5;
        }

        ::ng-deep s25-ng-bulk-edit-data-list s25-ng-table .s25-ng-table--header:not(:first-child),
        ::ng-deep s25-ng-bulk-edit-data-list s25-ng-table .s25-ng-table--cell:not(:first-child) {
            border-left: 1px solid #e5e5e5;
        }

        ::ng-deep .nm-party--on s25-ng-bulk-edit-data-listt s25-ng-table .s25-ng-table--table {
            border: 1px solid rgba(255, 255, 255, 0.24);
        }

        ::ng-deep .nm-party--on s25-ng-bulk-edit-data-list s25-ng-table tbody tr:not(:last-of-type) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.24);
        }

        ::ng-deep .nm-party--on s25-ng-bulk-edit-data-list s25-ng-table .s25-ng-table--header:not(:first-child),
        ::ng-deep .nm-party--on s25-ng-bulk-edit-data-list s25-ng-table .s25-ng-table--cell:not(:first-child) {
            border-left: 1px solid rgba(255, 255, 255, 0.24);
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditDataListComponent implements OnInit {
    @Input() type: Item.Id;
    @Input() query: string;
    @Input() cacheId: number;
    @Input() pageSize?: number;
    @Input() dateView?: ViewButtonValue;

    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;

    isInit = false;
    startDate: ISODateString;
    endDate: ISODateString;
    dateFormat: string;
    timeFormat: string;
    dateTimeFormat: string;
    S25Const = S25Const;
    data: any = [];
    userId: number;
    workflowChained: boolean;
    sortColumn: string;
    sortOrder: "asc" | "desc";
    selectedItems: any = [];
    userName: string;
    action: boolean = false;
    header: string;
    chosenModels: S25ItemI[] = [];
    scrollHasMorePages: boolean = false;

    tableData: Table.DataSource = {
        type: "infinite scroll",
        dataSource: this.getRows,
        columns: [],
        hasMorePages: this.hasMorePages,
        customRowCount: this.getRowCount,
    };

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    async ngOnInit() {
        const [dateFormat, timeFormat, dateTimeFormat, userId, userName, workflowPref] = await Promise.all([
            UserprefService.getS25Dateformat(),
            UserprefService.getS25Timeformat(),
            UserprefService.getS25DateTimeformat(),
            ContactService.getCurrentId(),
            ContactService.getCurrentUsername(),
            TaskTiersService.isWorkflowChained(),
        ]);
        this.header = S25Util.firstCharToUpper(S25Const.itemId2Name[this.type]) + " List";
        this.dateFormat = dateFormat;
        this.timeFormat = timeFormat;
        this.dateTimeFormat = dateTimeFormat;
        this.userId = userId;
        this.userName = userName;
        this.workflowChained = workflowPref;
        this.isInit = true;
        this.userName === "service25" ? (this.pageSize = 1500) : (this.pageSize = 1000);
        this.cd.detectChanges();
    }

    @Bind
    async getRows(query: Table.PaginatedQuery): Promise<Table.DataSourceResponse> {
        const data = (await this.getData(query)) || [];
        return {
            rows: data.rows.map((row: any) => this.mapToRow(data.cols, row)),
            totalRows: data.rows?.[0]?.count,
        };
    }

    async getData(tableQuery: Table.PaginatedQuery) {
        if (tableQuery.forceRefresh) this.cacheId = undefined; // If refresh is forced, don't reuse cache
        if (tableQuery.sortColumn.id !== this.sortColumn || tableQuery.sortColumn.order !== this.sortOrder) {
            // If sorting changed, reset pagination
            this.cacheId = undefined;
            this.tableComponent.currentPage = 0;
            tableQuery.page = 0;
        }
        this.sortColumn = tableQuery.sortColumn.id;
        this.sortOrder = tableQuery.sortColumn.order;

        if (this.type === Item.Ids.Task) {
            const taskCount = this.data?.taskCount;
            this.data = await ListService.getTaskData({
                query: this.query,
                page: tableQuery.page + 1,
                pageSize: this.pageSize,
                sortColumn: tableQuery.sortColumn.id,
                sortOrder: tableQuery.sortColumn.order,
                cacheId: this.cacheId,
            });
            if (!this.data.taskCount) this.data.taskCount = taskCount;
            this.scrollHasMorePages = this.pageSize * (tableQuery.page + 1) < this.data.taskCount;
        } else {
            this.data = await ListService.getData2({
                itemType: S25Const.itemId2Name[this.type],
                dateOption: this.dateView,
                startDate: this.startDate,
                endDate: this.endDate,
                page: tableQuery.page + 1,
                itemsPerPage: this.pageSize,
                sort: { column: tableQuery.sortColumn.id, order: tableQuery.sortColumn.order },
                query: this.query,
                cacheId: this.cacheId,
            });
            this.scrollHasMorePages = this.pageSize * (tableQuery.page + 1) < this.data.rows?.[0]?.count;
        }
        this.cacheId = this.data.cacheId;

        // Update columns in table
        let columns = this.data.cols.map((column: any) => {
            const data: Table.Column = {
                header: column.name,
                id: column.prefname,
                sortable: !!column.sortable,
            };

            switch (column.prefname) {
                case "event_name":
                case "name": // Location name
                case "event": // Task event
                case "last_name": // Contact
                    data.content = { component: S25ItemGenericComponent };
                    break;
                case "locations":
                case "resources":
                case "organization":
                    data.content = { component: GenericTableListComponent };
                    break;
                case "task_item":
                    data.content = { component: S25TaskEditComponent };
                    break;
                case "status":
                    data.content = { component: S25TaskStatusComponent };
                    break;
                case "actions":
                    data.content = { component: S25TaskActionComponent };
                    break;
                case "respond_by":
                    data.content = { component: TableEditableDateComponent };
                    data.width = 80;
                    break;
                default:
            }
            return data;
        });

        //bulk edit doesn't dispaly all columns like in search result list
        let displayCol: any[] = [];
        switch (this.type) {
            case Item.Ids.Event:
                displayCol = ["Name", "Title", "Reference", "Type", "Start Date", "Start Time", "State"];
                columns = columns.filter((item: any) => displayCol.includes(item.header));
                break;
            case Item.Ids.Location:
                displayCol = ["Name", "Formal Name", "Max Capacity"];
                columns = columns.filter((item: any) => displayCol.includes(item.header));
                break;
            case Item.Ids.Organization:
                displayCol = ["Name", "Title", "Type", "Accounting Code"];
                columns = columns.filter((item: any) => displayCol.includes(item.header));
                break;
            case Item.Ids.Resource:
                displayCol = ["Name", "Quantity"];
                columns = columns.filter((item: any) => displayCol.includes(item.header));
                break;
        }

        if (this.selectedItems.length > 0) this.tableComponent.selected = new Set(this.selectedItems);
        this.tableComponent.setColumns(columns);
        this.deleteTempSearch();
        this.cd.detectChanges();

        return this.data;
    }

    @Bind
    mapToRow(columns: any[], row: any): Table.Row {
        const cells: Table.Row["cells"] = {};
        for (let i = 0; i < columns.length; i++) {
            const [key, val] = this.getCell(columns[i], row.row[i]);
            cells[key] = val;
        }

        //task itemIds examples: "task_multi_484572" , "task524046" , "todo523782"
        let contextId = "";
        if (this.type === Item.Ids.Task) {
            contextId =
                (row.row[3].isTodo ? "todo" : "task") + (row.row[6].itemCount > 1 ? "_multi_" : "") + row.row[6].taskId;
        }

        return {
            id: this.type === Item.Ids.Task ? contextId : row.contextId,
            name: this.type === Item.Ids.Task ? row.row[0].itemName : row.row[1].itemName,
            cells,
        };
    }

    getCell(column: any, data: any): [string, Table.Cell] {
        let modelBean: any;
        switch (column.prefname) {
            case "event_name":
            case "name": // Location name
            case "last_name": // Contact
                modelBean = { itemTypeId: data.itemTypeId, itemId: data.itemId, itemName: data.itemName };
                return [column.prefname, { inputs: { modelBean } }];
            case "event": // Task event
                if (!data.itemName)
                    return [column.prefname, { component: undefined, text: "none", className: "italic" }];
                modelBean = { itemTypeId: data.itemTypeId, itemId: data.itemId, itemName: data.itemName };
                return [column.prefname, { inputs: { modelBean } }];
            case "bldg_name":
                return [column.prefname, { text: data.itemName }];
            case "locations":
            case "resources":
            case "organization":
                const items = data.subject?.map((sub: any) => ({
                    component: S25ItemGenericComponent,
                    inputs: { modelBean: { itemTypeId: sub.itemTypeId, itemId: sub.itemId, itemName: sub.itemName } },
                }));
                return [column.prefname, { inputs: { items: items || [], hasDecoration: false } }];
            case "start_date":
            case "first_date": // Task first date
                return [column.prefname, { text: S25Datefilter.transform(data, this.dateFormat) as string }];
            case "start_time":
                return [column.prefname, { text: S25Datefilter.transform(data, this.timeFormat) as string }];
            case "creation_date":
                return [column.prefname, { text: S25Datefilter.transform(data, this.dateTimeFormat) as string }];
            case "state_name":
                return [column.prefname, { text: data, className: `state${data}` }];
            case "task_item":
                const in1 = { showIcon: true, taskBlocked: this.workflowChained && data.taskBlocked, taskModel: data };
                const out1 = { onRefresh: () => this.tableComponent.refresh(true) };
                return [column.prefname, { inputs: in1, outputs: out1 }];
            case "status": // Task status
                return [column.prefname, { inputs: { task: data } }];
            case "actions":
                return [column.prefname, { inputs: data, outputs: { stateChange: this.onTaskAction } }];
            case "respond_by":
                const in2 = { val: data.respond_by, readOnly: data.assigned_to_id !== this.userId };
                const out2 = {
                    valChange: (val: Date, row: Table.Row, instance: TableEditableDateComponent) => {
                        const isTodo = parseInt(data.task_type) === 5 || parseInt(data.task_type) === 6;
                        if (isTodo) TaskService.putTodoDueDate(data.task_id, val);
                        else TaskService.putEventTaskDueDate(data.event_id, data.task_id, val);
                    },
                };
                return [column.prefname, { inputs: in2, outputs: out2 }];
            default:
                return [column.prefname, { text: data }];
        }
    }

    onRefresh() {
        return this.tableComponent.refresh(true);
    }

    @Bind
    onTaskAction(state: Task.State, row: Table.Row) {
        // Update task item column
        const taskItem = row.cells.task_item.instance as S25TaskEditComponent;
        if (taskItem) {
            taskItem.taskModel.itemStateId = state;
            taskItem.refresh();
        }
        // Update status column
        const status = row.cells.status.instance as S25TaskStatusComponent;
        if (status) {
            status.task.task_state = state;
            status.refresh();
        }
    }

    @Bind
    getRowCount(counts: Table.RowCounts) {
        const type = S25Util.firstCharToUpper(S25Const.itemId2Name[this.type]) + (counts.total === 1 ? "" : "s");
        if (this.type === Item.Ids.Task && this.data.taskCount !== counts.total) {
            const taskItems = `Task Item${counts.total === 1 ? "" : "s"}`;
            return `${counts.total} Matching ${taskItems} with ${this.data.taskCount} Tasks`;
        }

        return `${counts.total} Matching ${type}`;
    }

    @Bind
    hasMorePages() {
        return this.scrollHasMorePages;
    }

    selectedChange(selectItem: any) {
        this.selectedItems = Array.from(selectItem);
        this.chosenModels = this.tableComponent.filteredRows
            .filter((r: Table.Row) => this.selectedItems.includes(r.id))
            .map((d) => ({
                itemId: d.id,
                itemName: d.name,
            }));
        this.cd.detectChanges();
    }

    goToAction() {
        this.action = true;
        this.cd.detectChanges();
    }

    goBack() {
        history.back();
    }

    actionChange(e: boolean) {
        this.action = false;
        this.cd.detectChanges();
    }

    deleteTempSearch() {
        if (this.query.indexOf("query_id") > -1) {
            const regex = /query_id=(\d+)/;
            const queryId: number = parseInt(this.query.match(regex)[1]);
            return SearchService.deleteTempSearchByQueryId(queryId, this.type);
        }
    }
}
