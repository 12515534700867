//@author travis

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { jSith } from "../../../util/jquery-replacement";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { S25BulkEditUtil } from "./../s25.bulk.edit.util";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-bulk-edit-save")
@Component({
    selector: "s25-ng-bulk-edit-save",
    template: `
        <div class="c-displayBlock c-padding-bottom--double">
            <button
                [ngClass]="
                    isDanger
                        ? 'aw-button aw-button--danger--outline  c-margin-top--single c-margin-right--quarter'
                        : 'aw-button aw-button--primary c-margin-top--single c-margin-right--quarter'
                "
                (click)="update()"
                [disabled]="this.updating || this.buttonDisabled"
            >
                {{ this.updating ? "updating...." : this.buttonText }}
            </button>
            <!-- <button class="aw-button aw-button--outline c-margin-top--single" (click)="cancel()"> Cancel </button> -->
        </div>

        <s25-loading-inline model="{}"></s25-loading-inline>

        <div
            *ngIf="this.message"
            class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single"
            role="alert"
        >
            <div class="cn-alert__icon cn-icon" name="alert--info">
                <svg class="cn-svg-icon" role="img">
                    <title>Success Alert</title>
                    <use
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                    ></use>
                </svg>
            </div>
            <div class="cn-alert__label">
                <span>{{ this.message }}</span>
            </div>
        </div>
        <s25-ng-bulk-edit-issues-list
            *ngIf="issueList && issueList.length > 0"
            [title]="'No Permissions List'"
            [items]="issueList"
        ></s25-ng-bulk-edit-issues-list>
        <s25-ng-bulk-edit-issues-list
            *ngIf="lockedIssueList && lockedIssueList.length > 0"
            [title]="'Locked List'"
            [items]="lockedIssueList"
        ></s25-ng-bulk-edit-issues-list>

        <s25-ng-bulk-edit-issues-list
            *ngIf="noUpdateList && noUpdateList.length > 0"
            [title]="'No Update List'"
            [items]="noUpdateList"
        ></s25-ng-bulk-edit-issues-list>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditSaveComponent implements OnInit {
    @Input() successMsg?: string = "Success!";
    @Input() buttonText?: string;
    @Input() submitFn: Function; //Save function that returns a promise.
    @Input() buttonDisabled?: boolean = false;
    @Input() isDanger?: boolean = false;

    // @Output() onSubmit = new EventEmitter<any>();
    message: string = "";
    updating: boolean;
    subscription: any;
    issueList: any = [];
    lockedIssueList: any = [];
    noUpdateList: any = [];

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit(): void {
        this.buttonText = this.buttonText || "Update";
        this.cd.detectChanges();
    }

    reset() {
        this.message = "";
        this.updating = false;
        this.cd.detectChanges();
    }

    update() {
        this.updating = true;
        S25LoadingApi.init(this.elementRef.nativeElement);

        jSith.resolve(this.submitFn()).then(
            (resp: any) => {
                S25BulkEditUtil.done(this.elementRef.nativeElement);
                this.updating = false;
                if (resp) this.message = this.successMsg; //"Success!";
                this.issueList = S25BulkEditUtil.parseNoPermsList(resp);
                this.lockedIssueList = S25BulkEditUtil.parseLockedList(resp);
                this.noUpdateList = S25BulkEditUtil.parseNoUpdateList(resp);
                this.cd.detectChanges();
            },
            (err: any) => {
                this.reset();
                S25BulkEditUtil.err(err, this.elementRef.nativeElement);
            },
        );
    }
}
