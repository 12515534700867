import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { LangService } from "../../services/lang.service";
import { ContactService } from "../../services/contact.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";

export interface changePasswordI {
    password?: string;
    retypedPassword?: string;
}

@TypeManagerDecorator("s25-ng-change-password")
@Component({
    selector: "s25-ng-change-password",
    template: `
        <div>
            <s25-loading-inline [model]="{}"></s25-loading-inline>
            <div *ngIf="isInit" class="change-password--wrapper">
                <div class="c-margin-bottom--half">
                    <label for="newPassword">{{ userPrefsLang.new_password }}: </label>
                    <input type="password" [(ngModel)]="data.password" class="c-input" id="newPassword" />
                </div>
                <div>
                    <label for="confirmPassword">Confirm {{ userPrefsLang.new_password }}: </label>
                    <input type="password" [(ngModel)]="data.retypedPassword" class="c-input" id="confirmPassword" />
                </div>
                <div class="c-margin-top--half c-modal-info" style="font-style:italic; color:#555555;">
                    {{ contactInfoLang.password_characters }}
                </div>
                <br />
                <div *ngIf="showResponse && wasSuccessful" style="text-align:center; padding:10px; font-weight:bold;">
                    {{ userPrefsLang.password_change_success }}
                </div>
                <div *ngIf="showResponse && !wasSuccessful" style="text-align:center; padding:10px; font-weight:bold;">
                    {{ userPrefsLang.password_change_fail }}
                </div>

                <button
                    class="aw-button aw-button--primary change-password--wrapper-save"
                    (click)="resetPassword()"
                    [disabled]="showResponse"
                >
                    {{ userPrefsLang.change_password_button }}
                </button>
            </div>
        </div>
    `,
    styles: `
        .change-password--wrapper {
            width: fit-content;
            padding-left: 1.5em;
        }
        .change-password--wrapper-save {
            margin-top: 1em;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ChangePasswordComponent implements OnInit {
    @Input() data: changePasswordI;

    wasSuccessful: boolean;
    showResponse: boolean;
    userPrefsLang: any;
    contactInfoLang: any;
    title: string;
    passwordsNotEqual: string;
    isInit: boolean;

    constructor(
        private elementRef: ElementRef,
        private changeDetector: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        LangService.getLang().then((lang) => {
            this.wasSuccessful = false;
            this.showResponse = false;
            this.userPrefsLang = lang.div.controls.application.text.user_prefs;
            this.contactInfoLang = lang.div.controls.application.text.contact_info;
            this.title = this.userPrefsLang.change_password;
            this.passwordsNotEqual = "Passwords not equal";

            S25LoadingApi.destroy(this.elementRef.nativeElement);
            this.isInit = true;
            this.changeDetector.detectChanges();
        });
    }

    resetPassword() {
        if (!this.validatePassword(this.data.password)) return;
        return ContactService.setPassword(this.data.password)
            .then((resp) => {
                setTimeout(() => {
                    if (S25Util.valueFind(resp, "msg_id", "CO_I_SAVE")) {
                        this.wasSuccessful = true;
                    }
                    this.showResponse = true;
                    this.reset();
                });
            })
            .catch(() => {
                this.showResponse = true;
                this.reset();
            });
    }

    reset() {
        this.data.password = "";
        this.data.retypedPassword = "";
        this.changeDetector.detectChanges();
    }

    validatePassword(password: string) {
        let validPattern = /^[A-Z0-9_]+$/i,
            isValid = true;
        if (!validPattern.test(password)) {
            isValid = false;
        }
        if (!password || password.length === 0) {
            alert(this.userPrefsLang.enter_new_password);
            return false;
        } else if (!isValid) {
            alert(this.userPrefsLang.invalid_password_characters);
            return false;
        } else if (password.length < 5) {
            alert(this.userPrefsLang.invalid_password_length);
            return false;
        } else if (password !== this.data.retypedPassword) {
            alert(this.passwordsNotEqual);
            return false;
        }
        return true;
    }
}
