//@author travis
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-bulk-edit-issues-list")
@Component({
    selector: "s25-ng-bulk-edit-issues-list",
    template: ` <div class="cn-alert cn-alert--danger c-displayBlock">
        <div>{{ title }}</div>
        <table class="rose-object-occurrences-table bulk-edit-table">
            <thead>
                <tr>
                    <th *ngIf="reference">Reference</th>
                    <th *ngIf="eventName">Name</th>
                    <th *ngIf="objectName">Name</th>
                    <th *ngIf="itemName">Name</th>
                    <th *ngIf="itemId">Id</th>
                    <th *ngIf="message">Message</th>
                    <th *ngIf="hasRoomsBool">Rooms</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let e of items">
                    <td *ngIf="reference">{{ e.event_locator }}</td>
                    <td *ngIf="eventName">{{ e.event_name }}</td>
                    <td *ngIf="objectName">{{ e.objectName }}</td>
                    <td *ngIf="itemName">{{ e.itemName }}</td>
                    <td *ngIf="itemId">{{ e.itemId }}</td>
                    <td *ngIf="message">{{ e.message }}</td>
                    <!--<td *ngIf="hasRoomsBool">
                        <span *ngFor="let r of e.rooms | limitTo: 10 track by $index">
                            {{(r.room_short + ($last ? "" : ", "))}}
                        </span>
                        <span *ngIf="e.rooms.length > 10">...</span>
                    </td>-->
                </tr>
            </tbody>
        </table>
    </div>`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEditIssuesListComponent implements OnInit {
    @Input() items: any;
    // @Input() itemTypeId: number;
    @Input() title: string;
    @Input() hasRooms?: any;

    reference: string;
    eventName: string;
    itemName: string;
    itemId: string;
    objectName: string;
    message: string;
    hasRoomsBool: boolean;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {}

    ngOnInit(): void {
        this.hasRoomsBool = S25Util.toBool(this.hasRooms);
        this.eventName = this.items.length && this.items[0].event_name;
        this.objectName = this.items.length && this.items[0].objectName;
        this.itemId = this.items.length && this.items[0].itemId;
        this.itemName = this.items.length && this.items[0].itemName;
        this.reference = this.items.length && this.items[0].event_locator;
        this.message = this.items.length && this.items[0].message;

        this.cd.detectChanges();
    }

    reset() {
        // this.message = "";
        // this.updating = false;
        this.cd.detectChanges();
    }
}
